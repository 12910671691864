
import {Component, Mixins} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import EvrakEkleForm from "@/components/eicrapro/EvrakEkleForm.vue";
import FormButton from "@/components/dialogs/FormButton.vue";

@Component({
  components: {FormButton, EvrakEkleForm, FormDialog, DeleteDialog, FormWrapper}
})
export default class GonderilecekEvrakList extends Mixins(SimpleInputMixin) {
  headers = [
    {text: "Seçilen Evrak", value: "dosya.name"},
    {text: "Seçilen Ek", value: "ekDosyalar"},
    {text: "Açıklama", value: "aciklama"},
    {text: "İşlemler", value: "actions", sortable: false, width: 50},
  ];

  onAdd(value: any) {
    this.localValue.push(value);
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input()
  }

  onSubmit() {
    this.$emit("onSuccess", this.localValue);
  }
}
