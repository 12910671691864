
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import Crypto from "crypto";

@Component({
  components: {DeleteDialog, FormWrapper}
})
export default class EvrakEkleForm extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: FormWrapper;

  mdb = new MDB();
  localValue!: {
    seciliTur: any,
    aciklama: string,
    dosya: File,
    dosyaData: {
      base64: string | null,
      name: string | null,
      type: string | null,
      dosya: File | null,
      hash: string
    },
    ekDosyalar: Array<File>,
    ekDosyalarData: Array<{
      base64: string | null,
      name: string | null,
      type: string | null,
      dosya: File | null,
      hash: string
    }>
  };

  async onDosyaChange(file: any) {
    if (file) {
      this.$helper.convertToBase64(file).then((base64File: any) => {
        this.localValue.dosyaData.base64 = base64File;
        let hash = Crypto.createHash('sha1').update((base64File.split(','))[1]).digest().toString('hex');
        this.localValue.dosyaData.hash = hash;
      });
      this.localValue.dosyaData.dosya = file;
      this.localValue.dosyaData.name = file.name;
      this.localValue.dosyaData.type = file.type;
    } else {
      this.localValue.dosyaData = {base64: null, name: null, type: null, dosya: null, hash: ''};
    }
  }

  async onEkDosyaChange(files: any) {
    this.localValue.ekDosyalarData = [];
    if (files.length > 0) {
      for (const file of files) {
        this.$helper.convertToBase64(file).then((base64File: any) => {
          let hash = Crypto.createHash('sha1').update((base64File.split(','))[1]).digest().toString('hex');
          this.localValue.ekDosyalarData.push({
            base64: base64File,
            name: file.name,
            type: file.type,
            dosya: file,
            hash: hash
          });
        });
      }
    }
  }

  fileRule(dosya: any, zorunluMu: boolean) {
    if (dosya) {
      let uzanti = dosya.name.split('.').pop();
      if (["udf", "pdf", "jpg", "jpeg", "png", "tif", "tiff", "UDF", "PDF", "JPG", "JPEG", "PNG", "TIF", "TIFF"].indexOf(uzanti) >= 0) {
        if (dosya.size < 10485760)
          return true;
        else
          return "Doküman boyutu 10MB'dan fazla olamaz.";
      } else
        return "Doküman formatı UYAP'a uygun değil."
    } else {
      if (zorunluMu)
        return 'Lütfen bu alanı doldurun.';
      else
        return true;
    }
  }
}
