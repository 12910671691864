

import {Component, Vue} from "vue-property-decorator";
import EvrakGonderFormWizard from "@/components/forms/form-wizard/eicrapro/EvrakGonderFormWizard.vue";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, EvrakGonderFormWizard}
})
export default class EvrakGonderView extends Vue {
  taskTuru = TaskTuru;
}
